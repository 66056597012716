import React from 'react';

import {
  Alert,
  Loading,
  useRestaurantHomeView,
  AdminButton,
} from '@fingermarkglobal/cringer.components';

import { PrimaryHome } from '../../components/stateless/primary-home';
import { SecondaryHome } from '../../components/stateless/secondary-home';

const CustomHomeView = ({ isShowingCustomBackground = false }) => {
  const {
    t,
    state,
    loading,
    isCurrentLang,
    onAdminActivate,
    showSecondaryHome,
    accessibilityEnabled,
    languages,
    primaryAction,
    secondaryAction,
    onChangeLanguage,
    showSecondaryAction,
    card,
    cash,
    icons,
    isOnline,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  } = useRestaurantHomeView();
  const params = {
    accessibilityEnabled,
    languages,
    isCurrentLang,
    onChangeLanguage,
    primaryAction,
    secondaryAction: showSecondaryAction && secondaryAction,
    t,
    card,
    cash,
    icons,
    isShowingCustomBackground,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  };

  return (
    <div className="flex flex-col items-center justify-center h-full p-8">
      <AdminButton onActivate={onAdminActivate} />
      {loading && <Loading isFirstLoad={true} />}
      {state?.matches('failure') && <Alert type="danger" message={state.context.message} />}
      {state?.matches('success') &&
        (!isOnline ? (
          <Alert type="warning" message={t('homeConnectivityIssues')} />
        ) : showSecondaryHome ? (
          <SecondaryHome {...params} />
        ) : (
          <PrimaryHome {...params} />
        ))}
    </div>
  );
};

export { CustomHomeView };
