import React from 'react';

import {
  Footer,
  useTemplate,
  TimeoutModal,
  AccessibilityWrapper,
  AccessibilityButton,
} from '@fingermarkglobal/cringer.components';

import { Header } from '../../components/stateless/header';
import { Decoration } from '../../components/stateless/decoration';

import CartIconImage from '../../assets/images/cart-icon.svg';

const CustomDefaultTemplate = ({
  children,
  footerEnabled = true,
  headerEnabled = true,
  timeoutEnabled = true,
  templateOverride = false,
  accessibilityActionEnabled = true,
}) => {
  const {
    header,
    footer,
    isOnline,
    isCouponEnabled,
    isTimeoutEnabled,
    timeoutActionOverride,
    accessibilityEnabled,
    modalAccessibilityClass,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const showFooterAccessibilityButton = accessibilityActionEnabled && footerEnabled;
  const isAccessibilityButtonEnabled = accessibilityActionEnabled || showFooterAccessibilityButton;

  const showLogo = accessibilityEnabled && isAccessibilityButtonEnabled;

  return (
    <div className="flex flex-col justify-between h-screen">
      <Decoration showLogo={showLogo} />

      <AccessibilityWrapper
        className={`bg-default flex flex-col ${!accessibilityEnabled && 'accessibility-static'}`}
        isAccessibilityButtonEnabled={isAccessibilityButtonEnabled}
      >
        <div className="flex flex-col w-screen h-full">
          {headerEnabled && <Header {...header} isCouponEnabled={isCouponEnabled} />}

          <div className="flex flex-col h-full overflow-auto">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              accessibilityEnabled={accessibilityEnabled}
              showAccessibilityButton={showFooterAccessibilityButton}
              OverwriteCartIcon={CartIconImage}
            />
          )}

          {accessibilityActionEnabled && (
            <AccessibilityButton className="absolute bottom-0 left-0 z-40 block w-24 h-24 p-2 my-8 ml-8 bg-white shadow-lg" />
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
